import http from '@api/http'
import jwtConfig from '@/jwtConfig'

class Auth {
  constructor(_http) {
    this.http = _http
  }

  login(data, opt = {}) {
    return this.http.post(jwtConfig.loginEndpoint, data, opt)
  }

  register(data, opt = {}) {
    return this.http.post(jwtConfig.registerEndpoint, data, opt)
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    // state.userData = {}
    // state[config.storageTokenKeyName] = null
    // state[config.storageRefreshTokenKeyName] = null
    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem(jwtConfig.storageUserDataKeyName)
    localStorage.removeItem(jwtConfig.storageTokenKeyName)
    localStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)

    // location.href = '/login'

    // return this.http.get(jwtConfig.logoutEndpoint, opt)
    return Promise.resolve(true)
  }

  signOut(opt = {}) {
    return this.http.get(jwtConfig.logoutEndpoint, opt)
  }

  refreshToken(data, opt = {}) {
    return this.http.post(jwtConfig.refreshEndpoint, data, opt)
  }

  reject(data, opt = {}) {
    return this.http.post('auth/reject', data, opt)
  }

  forgetPassword(data, opt = {}) {
    return this.http.post(jwtConfig.resetPasswordEndpoint, data, opt)
  }

  updatePassword(data, opt = {}) {
    return this.http.put(jwtConfig.resetPasswordEndpoint, data, opt)
  }

  // 2FA
  twoFactorSecretGenerate(...arg) {
    return this.http.get(jwtConfig.twoFactorSecretGenerateEndpoint, ...arg)
  }

  twoFactorEnable(data, opt = {}) {
    return this.http.post(jwtConfig.twoFactorEnableEndpoint, data, opt)
  }

  twoFactorDisable(...arg) {
    return this.http.delete(jwtConfig.twoFactorDisableEndpoint, ...arg)
  }

  twoFactorVerify(data, opt = {}) {
    return this.http.post(jwtConfig.twoFactorVerifyEndpoint, data, opt)
  }
}

const AuthRequest = new Auth(http)

export default AuthRequest
