<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Estelarbet
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Autenticación de dos factores
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            {{ twoFactorEnable ? 'Autenticación de dos factores' : 'Configura la autenticación de dos factores' }}
            Enter your email and we'll send you instructions to reset your password
          </b-card-text> -->

          <b-card-text v-if="!twoFactorEnable">
            <p class="mb-1 fs-13 ms-0 text-start">
              Descarga una aplicación de autenticación
            </p>
            <p class="mb-4 fs-13 ms-0 text-start font-weight-bolder">
              Android, IOS - Google Authenticator <br>
              Windows Phone - Authenticator
            </p>
            <h5 class="mb-2 text-muted fs-13 ms-0 text-center">
              Escanea el QR con la aplicación
            </h5>

            <div
              ref="refContentQr"
              class="d-flex align-items-center justify-content-center mb-1"
            />
            <div
              v-if="loading"
              class="d-flex align-items-center justify-content-center mb-1"
              style="height: 200px;"
            >
              <div class="spinner-border spinner-border-sm" />
            </div>
            <div
              v-else-if="twoFactorData"
              class="text-center d-flex justify-content-center mb-4"
            >
              <p
                class="fs-13 mb-0 cursor-pointer font-weight-bolder"
                @click="() => showSecret = !showSecret"
              >
                {{ showSecret ? `Secret: ${twoFactorData.secret}` : "Usar clave secreta" }}
              </p>
            </div>
          </b-card-text>

          <b-card-text>
            <b-alert
              :show="!!err"
              variant="danger"
              class="p-1"
            >{{ err }}</b-alert>
          </b-card-text>

          <!-- form -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              class="auth-2fa-form mt-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <validation-provider
                #default="validationContext"
                name="Code"
                rules="required"
              >
                <b-form-group
                  label="Ingresa el código de 6 dígitos generado por la aplicación"
                  label-for="one_time_password"
                >
                  <b-form-input
                    id="one_time_password"
                    v-model="currencyData.one_time_password"
                    type="number"
                    :state="getValidationState(validationContext)"
                    name="one_time_password"
                    autofocus
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Verificar código
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link @click="backToLogin">
              <feather-icon icon="ChevronLeftIcon" /> Ir al login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { required } from '@validations'
import store from '@/store/index'
import { toast } from '@/libs/toastification-generic'
import formValidation from '@/@core/comp-functions/forms/form-validation'
import AuthRequest from '@api/auths'
import jwtConfig from '@/jwtConfig'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BCardTitle,
    BCardText,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      required,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    const blankData = {
      one_time_password: '',
    }

    const currencyData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      currencyData.value = JSON.parse(JSON.stringify(blankData))
    }

    const refContentQr = ref(null)
    const twoFactorEnable = ref(false)
    const err = ref('')
    const showSecret = ref(false)
    const formLoading = ref(false)
    const loading = ref(false)
    const twoFactorData = ref(null)

    const backToLogin = (e) => {
      e.preventDefault()

      localStorage.removeItem(jwtConfig.storageTokenKeyName)
      localStorage.removeItem(jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem(jwtConfig.storageUserDataKeyName)

      setTimeout(() => {
        router.replace({ name: 'auth-login' })
      }, 300)
    }

    const onSubmit = async () => {
      formLoading.value = true

      err.value = ''

      try {
        const form = { ...currencyData.value }

        const { data } = !twoFactorEnable.value
          ? await AuthRequest.twoFactorEnable(form)
          : await AuthRequest.twoFactorVerify(form)

        const user = JSON.parse(localStorage.getItem(jwtConfig.storageUserDataKeyName))
        user.twoFactorEnable = true
        user.is2FAVerified = true

        if (user.name) {
          localStorage.setItem(jwtConfig.storageTokenKeyName, data.token)
          localStorage.setItem(jwtConfig.storageRefreshTokenKeyName, data.token)
          localStorage.setItem(jwtConfig.storageUserDataKeyName, JSON.stringify(user))

          router.replace('/').then(() => {
            toast({
              title: `Bienvenido ${user.name}`,
              icon: 'CoffeeIcon',
              variant: 'success',
            })
          })
        }
      } catch (error) {
        console.log(error)
        if (error.response.data.message) {
          err.value = error.response.data.message
        } else if (error.message) {
          err.value = error.message
        }
      } finally {
        formLoading.value = false
      }
    }

    const init = async () => {
      loading.value = true

      if (refContentQr.value) refContentQr.value.innerHTML = ''

      try {
        const { data } = await AuthRequest.twoFactorSecretGenerate()

        twoFactorData.value = data

        if (refContentQr.value) {
          refContentQr.value.innerHTML = ''

          refContentQr.value.insertAdjacentHTML('afterbegin', data.qr_image)
        }
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    onMounted(() => {
      const user = JSON.parse(localStorage.getItem(jwtConfig.storageUserDataKeyName))

      const enable = user ? user.twoFactorEnable : false
      twoFactorEnable.value = enable

      if (!enable) {
        init()
      }
    })

    return {
      refFormObserver,
      getValidationState,
      resetForm,

      currencyData,
      refContentQr,
      twoFactorData,
      twoFactorEnable,
      showSecret,
      loading,
      formLoading,
      onSubmit,
      err,

      backToLogin,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
